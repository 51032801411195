import { useState, useEffect } from "react";

const useFirestore = (collection, category) => {
  const [imgs, setImgs] = useState([]);

  async function getImages() {
    const NEXT_HYGRAPH_ENDPOINT = process.env.REACT_APP_NEXT_HYGRAPH_ENDPOINT;
    let response;

    if (category !== "all") {
      response = await fetch(NEXT_HYGRAPH_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query MyQuery {
            assets(where: {category: ${category}}) {
              id
              category
              url
              }
              }`,
        }),
      });
    } else {
      response = await fetch(NEXT_HYGRAPH_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `query MyQuery {
                assets {
                  id
                  category
                  url
                  }
                  }`,
        }),
      });
    }

    const json = await response.json();
    setImgs(json.data.assets);
  }

  useEffect(() => {
    getImages();
  }, [collection, category]);

  return { imgs };
};

export default useFirestore;
